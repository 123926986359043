import {
  Box,
  Breadcrumbs,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigation, Autoplay, Pagination, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PlaceIcon from "@mui/icons-material/Place";
import PageHeader from "../Components/PageHeader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BASEURL } from "../service/baseUrl";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import PhoneIcon from "@mui/icons-material/Phone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast, { Toaster } from "react-hot-toast";

const PropertyDetails = () => {
  const { id } = useParams();
  const [property, setProperty] = useState({});
  const [pageUrl, setPageUrl] = useState("");
  const admin = JSON.parse(localStorage.getItem("user"));

  //api call
  useEffect(() => {
    axios.get(`${BASEURL}/api/property/propertyById?id=${id}`).then((res) => {
      console.log(res.data);
      setProperty(res?.data?.data);
      setPageUrl(window.location.href);
    });
  }, []);

  //copy page url
  const handleCopyUrl = () => {
    const currentUrl = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        toast.success("URL copied to clipboard!", {
          position: "bottom-center",
          iconTheme: {
            primary: "#0866ff",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((err) => {
        toast.error("Failed to copy URL: ", err);
      });
  };

  //admin contact details
  const sellerPhoneNumber = "918848695305";
  const message = `Hi! I'm interested in this property: ${pageUrl}`;
  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${sellerPhoneNumber}?text=${encodedMessage}`;
  return (
    <>
      <PageHeader data={{ name: "Property Details", name2: "Property" }} />
      <Box sx={{ width: { md: "70%", xs: "100%" } }} m={"auto"} mt={2}>
        <Swiper
          style={{ boxShadow: "0 5px 20px rgba(0, 0, 0, 0.2)" }}
          autoplay={{
            delay: 6500,
          }}
          pagination={{
            clickable: true,
          }}
          freeMode={true}
          navigation={true}
          modules={[Navigation, Autoplay, Pagination, FreeMode]}
          className="mySwiper"
        >
          {property?.images?.map((image, index) => (
            <SwiperSlide key={index}>
              <Box
                height={{ md: 700, xs: 350 }}
                width={"100%"}
                component={"img"}
                sx={{ objectFit: "contain" }}
                src={image}
                alt={image.alt || "Image"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Container sx={{ mt: 3 }}>
        <>
          <Typography fontSize={{ md: 45, xs: 30 }} fontWeight={500}>
            {property?.propertyTitle}
          </Typography>
          <Typography fontSize={20} mt={2}>
            {property?.propertyDescription}
          </Typography>
        </>
        <Stack
          bgcolor={"#f8f8f8"}
          height={{ md: 160, xs: 360 }}
          mt={3}
          mb={5}
          p={3}
        >
          <Typography mb={2} fontSize={22} fontWeight={500}>
            Preview
          </Typography>
          <Divider />
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent={"space-around"}
            mt={3}
            spacing={{ md: 0, xs: 4 }}
          >
            <Stack direction={"row"}>
              <Stack bgcolor={"white"} width={40} p={2}>
                <img src={"/assets/download.svg"} />
              </Stack>
              <Stack ml={2} mt={1}>
                <Typography fontSize={15} color={"gray"}>
                  Room
                </Typography>
                <Typography fontSize={17} fontWeight={500}>
                  {property?.numberOfRooms} Room
                </Typography>
              </Stack>
            </Stack>
            {/* <Stack direction={"row"}>
              <Stack bgcolor={"white"} width={40} p={2}>
                <img src={"/assets/download (1).svg"} />
              </Stack>
              <Stack ml={2} mt={1}>
                <Typography fontSize={15} color={"gray"}>
                  Bed
                </Typography>
                <Typography fontSize={17} fontWeight={500}>
                  2 Beds
                </Typography>
              </Stack>
            </Stack> */}
            <Stack direction={"row"}>
              <Stack bgcolor={"white"} width={40} p={2}>
                <img src={"/assets/download (2).svg"} />
              </Stack>
              <Stack ml={2} mt={1}>
                <Typography fontSize={15} color={"gray"}>
                  Bath
                </Typography>
                <Typography fontSize={17} fontWeight={500}>
                  {property?.numberOfBathRooms} Bath
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"}>
              <Stack bgcolor={"white"} width={40} p={2}>
                <img src={"/assets/download (3).svg"} />
              </Stack>
              <Stack ml={2} mt={1}>
                <Typography fontSize={15} color={"gray"}>
                  Size
                </Typography>
                <Typography fontSize={17} fontWeight={500}>
                  {property?.squareFeet} sqft
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack bgcolor={"#f8f8f8"} mb={5} p={2}></Stack>
        </Stack>

        <Stack
          bgcolor={"#f8f8f8"}
          height={{ md: 190, xs: 330 }}
          mt={3}
          mb={5}
          p={3}
        >
          <Typography mb={2} fontSize={22} fontWeight={500}>
            Property Details
          </Typography>
          <Divider />
          <Stack direction={{ md: "row", xs: "column" }} mt={3} spacing={2}>
            <Stack spacing={2}>
              <Stack direction={"row"}>
                <CheckCircleIcon sx={{ color: "orangered" }} />
                <Typography fontSize={18} fontWeight={500}>
                  Price : ₹
                  {new Intl.NumberFormat("en-IN").format(property?.price)}
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <CheckCircleIcon sx={{ color: "orangered" }} />
                <Typography fontSize={18} fontWeight={500}>
                  Attached bathrooms : {property?.attachedBathRooms}
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <CheckCircleIcon sx={{ color: "orangered" }} />
                <Typography fontSize={18} fontWeight={500}>
                  Cent : {property?.cent}
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Stack direction={"row"}>
                <CheckCircleIcon sx={{ color: "orangered" }} />
                <Typography fontSize={18} fontWeight={500}>
                  Property Category : {property?.propertyCategory}
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <CheckCircleIcon sx={{ color: "orangered" }} />
                <Typography fontSize={18} fontWeight={500}>
                  For : {property?.serviceProvideType}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          bgcolor={"#f8f8f8"}
          height={{ md: 190, xs: 200 }}
          mt={3}
          mb={5}
          p={3}
        >
          <Typography mb={2} fontSize={22} fontWeight={500}>
            Contact Us{" "}
            {admin && (
              <IconButton sx={{ ml: 3 }} onClick={handleCopyUrl}>
                <ContentCopyIcon sx={{ color: "black" }} />
              </IconButton>
            )}
          </Typography>

          <Divider />
          <Stack direction={{ md: "row", xs: "column" }} mt={2} spacing={2}>
            <Stack spacing={2}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to={property?.locationUrl}
                target="_blank"
              >
                <Stack direction={"row"}>
                  <PlaceIcon sx={{ color: "orangered" }} />
                  <Typography
                    fontWeight={500}
                    ml={{ md: 2, xs: 1.5 }}
                    fontSize={15}
                  >
                    Property Location (Click here!)
                  </Typography>
                </Stack>
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to={whatsappUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Stack direction={"row"}>
                  <WhatsappIcon size={33} round />{" "}
                  <Typography
                    fontWeight={500}
                    ml={1}
                    mt={{ md: 1, xs: 0 }}
                    fontSize={15}
                  >
                    Interested? Please click here drop a message
                  </Typography>
                </Stack>
              </Link>
              <Link
                to={`tel:${sellerPhoneNumber}`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PhoneIcon sx={{ color: "#007bff" }} />
                <Typography
                  ml={{ md: 2, xs: 1.5 }}
                  mt={{ md: 1, xs: 0 }}
                  fontWeight={500}
                  fontSize={15}
                >
                  Click here to call the property seller!
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          padding: "10px",
          fontSize: "17px",
          fontFamily: "sans-serif",
        }}
      />
    </>
  );
};

export default PropertyDetails;
