import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../service/baseUrl";
import axios from "axios";
import { propertyEditValidationSchema } from "../formValidations/PropertyEditValidation.js";
import { useNavigate } from "react-router-dom";

const EditProperty = ({ id, close }) => {
  const [property, setProperty] = useState({});
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image1Preview, setImage1Preview] = useState("");
  const [image2Preview, setImage2Preview] = useState("");
  const [image3Preview, setImage3Preview] = useState("");
  const [image4Preview, setImage4Preview] = useState("");
  const [image5Preview, setImage5Preview] = useState("");
  const [image6Preview, setImage6Preview] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();

  //propetyById api func
  const getPropertyById = async () => {
    await axios
      .get(`${BASEURL}/api/property/propertyById?id=${id}`)
      .then((res) => {
        setProperty(res?.data?.data);
      });
  };

  //edit func
  const handleEdit = async () => {
    try {
      const { images, thumbnail, ...propertyData } = property;
      await propertyEditValidationSchema.validate(propertyData, {
        abortEarly: false,
      });
      const token = localStorage.getItem("token");
      try {
        const response = await axios.put(
          `${BASEURL}/api/property/updateproperty?id=${id}`,
          propertyData,
          {
            headers: {
              "Content-Type": "application/json",
              user_token: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          toast.success("Property updated!");
          setTimeout(() => {
            close();
          }, 2000);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setErrors(newErrors);
    }
  };

  //image edit func
  const handleImgEdit = async () => {
    const imageData = new FormData();

    // Append thumbnail
    imageData.append(
      "thumbnail",
      thumbnailImage ? thumbnailImage : property.thumbnail
    );

    // Append images, ensuring each one exists
    const images = [image1, image2, image3, image4];
    images.forEach((image, index) => {
      if (image) {
        imageData.append("images", image);
      } else if (property.images[index]) {
        imageData.append("images", property.images[index]); // Fallback to existing images
      }
    });
    const token = localStorage.getItem("token");
    try {
      const result = await axios.put(
        `${BASEURL}/api/property/property-img-update?id=${id}`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            user_token: `Bearer ${token}`,
          },
        }
      );

      if (result.data) {
        toast.success("Images updated successfully");
      }
    } catch (err) {
      // Improved error handling
      const errorMessage =
        err.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage);
    }
  };

  //images preview
  useEffect(() => {
    if (image1) {
      setImage1Preview(URL.createObjectURL(image1));
    }
    if (image2) {
      setImage2Preview(URL.createObjectURL(image2));
    }
    if (image3) {
      setImage3Preview(URL.createObjectURL(image3));
    }
    if (image4) {
      setImage4Preview(URL.createObjectURL(image4));
    }
    if (image5) {
      setImage5Preview(URL.createObjectURL(image5));
    }
    if (image6) {
      setImage6Preview(URL.createObjectURL(image6));
    }
    if (thumbnailImage) {
      setThumbnailPreview(URL.createObjectURL(thumbnailImage));
    }
  }, [image1, image2, image3, image4, image5, image6, thumbnailImage]);

  //propertytById api
  useEffect(() => {
    getPropertyById();
  }, []);

  return (
    <Stack bgcolor={"#f8f8f8"}>
      <>
        <Typography
          bgcolor={"black"}
          color={"white"}
          p={1}
          borderRadius={1}
          fontSize={20}
          fontWeight={500}
        >
          Property Basic Information
        </Typography>
        <Stack
          bgcolor={"white"}
          p={2}
          boxShadow={
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }
          borderRadius={1}
          mb={3}
        >
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Property Title
          </Typography>
          <TextField
            value={property?.propertyTitle}
            onChange={(e) =>
              setProperty({
                ...property,
                propertyTitle: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px", // Set the border radius
                "& fieldset": {
                  borderColor: "#aeb1b5", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5", // Keep border color the same on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange", // Border color when focused
                },
              },
            }}
            variant="outlined"
            placeholder="Property Title"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.propertyTitle}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Place
          </Typography>
          <TextField
            value={property?.place}
            onChange={(e) =>
              setProperty({ ...property, place: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Place"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.place}</FormHelperText>
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent={"space-between"}
            mt={2}
          >
            <Typography fontSize={16} fontWeight={500} mt={2}>
              Property Category
            </Typography>
            <FormControl sx={{ width: { md: 200, xs: "100%" } }}>
              <Select
                value={property?.propertyCategory || ""}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    propertyCategory: e.target.value,
                  })
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Border color when focused
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Keep it gray on hover
                  },
                }}
              >
                <MenuItem value={"House"}>House</MenuItem>
                <MenuItem value={"Villa"}>Villa</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: "red" }}>
              {errors.propertyCategory}
            </FormHelperText>
            <Typography fontSize={16} fontWeight={500} mt={2}>
              Property Business Type
            </Typography>
            <FormControl sx={{ width: { md: 200, xs: "100%" } }}>
              <Select
                value={property?.serviceProvideType || ""}
                onChange={(e) =>
                  setProperty({
                    ...property,
                    serviceProvideType: e.target.value,
                  })
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Border color when focused
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Keep it gray on hover
                  },
                }}
              >
                <MenuItem value={"Sale"}>Sale</MenuItem>
                <MenuItem value={"Rent"}>Rent</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: "red" }}>
              {errors.serviceProvideType}
            </FormHelperText>
          </Stack>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Property Description
          </Typography>
          <TextField
            value={property?.propertyDescription}
            onChange={(e) =>
              setProperty({
                ...property,
                propertyDescription: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: 100,
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Your Message"
            multiline
            rows={4}
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.propertyDescription}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Rooms
          </Typography>
          <TextField
            value={property?.numberOfRooms}
            onChange={(e) =>
              setProperty({
                ...property,
                numberOfRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Rooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.numberOfRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Bathrooms
          </Typography>
          <TextField
            value={property?.numberOfBathRooms}
            onChange={(e) =>
              setProperty({
                ...property,
                numberOfBathRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Bathrooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.numberOfBathRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Attached Bathrooms
          </Typography>
          <TextField
            value={property?.attachedBathRooms}
            onChange={(e) =>
              setProperty({
                ...property,
                attachedBathRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Attached Bathrooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.attachedBathRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Cent
          </Typography>
          <TextField
            value={property?.cent}
            onChange={(e) => setProperty({ ...property, cent: e.target.value })}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px", // Set the border radius
                "& fieldset": {
                  borderColor: "#aeb1b5", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5", // Keep border color the same on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange", // Border color when focused
                },
              },
            }}
            variant="outlined"
            placeholder="Cent"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.cent}</FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Area
          </Typography>
          <TextField
            value={property?.squareFeet}
            onChange={(e) =>
              setProperty({ ...property, squareFeet: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Sq Ft"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.squareFeet}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Price
          </Typography>
          <TextField
            value={property?.price}
            onChange={(e) =>
              setProperty({ ...property, price: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Price"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.price}</FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Location URL(GoogleMap)
          </Typography>
          <TextField
            value={property?.locationUrl}
            onChange={(e) =>
              setProperty({ ...property, locationUrl: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Price"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.locationUrl}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Thumbnail
          </Typography>
          <label htmlFor="thumbnailInput">
            <Stack
              bgcolor={"#dedede"}
              sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
              borderRadius={1}
            >
              <input
                onChange={(e) => setThumbnailImage(e.target.files[0])}
                id="thumbnailInput"
                style={{ display: "none" }}
                type="file"
              />
              <Box
                textAlign={"center"}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                {property?.thumbnail ? (
                  <Box
                    component={"img"}
                    width={{ md: 404, xs: 323 }}
                    height={275}
                    src={
                      thumbnailPreview ? thumbnailPreview : property?.thumbnail
                    }
                    alt=""
                  />
                ) : (
                  <>
                    {" "}
                    <PhotoLibraryIcon />
                    <Typography>Browse Image</Typography>
                  </>
                )}
              </Box>
            </Stack>
          </label>
          <FormHelperText sx={{ color: "red" }}>
            {errors.thumbnail}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Images
          </Typography>
          <Stack
            display={{ xs: "block", md: "flex", sm: "flex" }}
            flexDirection={"row"}
            sx={{ gap: 2 }}
            flexWrap={"wrap"}
          >
            <label htmlFor="img1">
              <Stack
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage1(e.target.files[0])}
                  id="img1"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image1Preview ? image1Preview : property?.images[0]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img2">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage2(e.target.files[0])}
                  id="img2"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image2Preview ? image2Preview : property?.images[1]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img3">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage3(e.target.files[0])}
                  id="img3"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image3Preview ? image3Preview : property?.images[2]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img4">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage4(e.target.files[0])}
                  id="img4"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image4Preview ? image4Preview : property?.images[3]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>
            <label htmlFor="img5">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage5(e.target.files[0])}
                  id="img5"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image5Preview ? image5Preview : property?.images[4]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>
            <label htmlFor="img6">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage6(e.target.files[0])}
                  id="img6"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {property?.images ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image6Preview ? image6Preview : property?.images[5]}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            {errors.images && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.images}
              </FormHelperText>
            )}
          </Stack>
          <Button
            onClick={handleImgEdit}
            sx={{
              marginTop: "15px",
              backgroundColor: "#0384fc",
              color: "white",
              "&:hover": { backgroundColor: "#0384fc" },
              width: "150px",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            Save Images
          </Button>
          <Button
            onClick={handleEdit}
            size="large"
            sx={{
              marginTop: "15px",
              borderRadius: 1,
              height: 55,
              backgroundColor: "#fc7f03",
              fontSize: 16,
              fontWeight: 500,
              color: "white",
              "&:hover": { backgroundColor: "#fc6f03" },
            }}
          >
            Update Property
          </Button>
        </Stack>
      </>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          padding: "10px",
          fontSize: "17px",
          fontFamily: "sans-serif",
        }}
      />
    </Stack>
  );
};

export default EditProperty;
