import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import React from "react";
import HomeIcon from "@mui/icons-material/Home";

const PageHeader = ({ data }) => {
  return (
    <>
      <Stack
        bgcolor="#181616"
        width="100%"
        height={{ md: 500, xs: 300 }}
        position="relative"
        borderTop={"1px solid gray"}
      >
        <Box
          component="img"
          src="https://cityscape.wowtheme7.com/assets/breadcrumb-img-DVKBF4db.png"
          width="100%"
          height="100%"
          objectFit="cover"
        />
        <Typography
          display="block"
          color="white"
          fontSize={{ md: 55, xs: 30 }}
          fontWeight={500}
          position="absolute" // Position typography absolutely
          top={{ md: "30%", xs: "40%" }} // Center vertically
          left={{
            md:
              data?.name === "Properties"
                ? "42%"
                : data?.name === "Property Details"
                ? "39%"
                : data?.name === "Account"
                ? "43%"
                : "",
            xs:
              data?.name === "Account"
                ? "35%"
                : data?.name === "Property Details"
                ? "21%"
                : data?.name === "Properties"
                ? "31%"
                : "",
          }} // Center horizontally
          transform="translate(-50%, -50%)" // Adjust for exact centering
        >
          {data?.name}
        </Typography>
        <Breadcrumbs
          sx={{ color: "white" }}
          separator=">"
          position="absolute"
          top={{ md: "50%", xs: "55%" }}
          left={{
            md:
              data?.name === "Properties"
                ? "44%"
                : data?.name === "Property Details"
                ? "45%"
                : data?.name === "Account"
                ? "42.5%"
                : "",
            xs:
              data?.name === "Account"
                ? "20%"
                : data?.name === "Properties"
                ? "25%"
                : data?.name === "Property Details"
                ? "26%"
                : "",
          }}
          transform="translate(-50%, -50%)"
        >
          <Link underline="hover" color="white" href="/">
            <Stack direction={"row"} spacing={1}>
              <HomeIcon /> <Typography fontSize={17}>Home</Typography>
            </Stack>
          </Link>
          <Link underline="hover" color="white" href="/propertyDetails">
            <Typography fontSize={17}>{data?.name2}</Typography>
          </Link>
        </Breadcrumbs>
      </Stack>
    </>
  );
};

export default PageHeader;
