import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Link,
  Grid,
  InputAdornment,
  IconButton,
  createTheme,
  ThemeProvider,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginInputValidation } from "../formValidations/LoginValidation.js";
import axios from "axios";
import { BASEURL } from "../service/baseUrl.js";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff9800",
    },
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  console.log(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await loginInputValidation.validate(formData, { abortEarly: false });
      const response = await axios.post(`${BASEURL}/api/auth/signin`, formData);
      if (response.data) {
        toast.success("Login successful");
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    } catch (err) {
      console.log(err);
      const newErrors = {};
      // Handle validation errors
      if (err.inner?.length) {
        err.inner.forEach(({ path, message }) => {
          newErrors[path] = message;
        });
      } else {
        // Handle backend or other errors
        toast.error(
          err.response?.data?.message || "An unexpected error occurred"
        );
      }
      setErrors(newErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#f5f5f5",
        }}
      >
        <Card sx={{ maxWidth: 1000, width: "100%", boxShadow: 3 }}>
          <Grid container>
            <Grid item xs={12} md={6} p={2}>
              <Box
                sx={{
                  backgroundImage: 'url("/assets/real-estate-cost.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  minHeight: 400,
                  borderRadius: 2,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} mt={{ md: 6, xs: 0 }}>
              <CardContent sx={{ p: 2 }}>
                <Typography fontSize={23} fontWeight={500} gutterBottom>
                  Login to Pathanamthitta Real Estate <span style={{fontSize:'14px'}}>(Admin only)</span>
                </Typography>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                />
                <FormHelperText sx={{ color: "red" }}>
                  {errors.email}
                </FormHelperText>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {errors.password}
                </FormHelperText>

                <Button
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ mt: 3, mb: 2, color: "white" }}
                >
                  LOGIN
                </Button>
                {/* <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2">
                      Don't Have An Account? <Link href="#" color="primary">Register</Link>
                    </Typography>
                  </Box> */}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          padding: "10px",
          fontSize: "17px",
          fontFamily: "sans-serif",
        }}
      />
    </ThemeProvider>
  );
};

export default Login;
