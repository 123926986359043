import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PropertyCard from "../Components/PropertyCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { makeStyles } from "@mui/styles";

// Import Swiper styles
import "swiper/css";

// import required modules
import axios from "axios";
import { BASEURL } from "../service/baseUrl";

const useStyles = makeStyles({
  solidButton: {
    backgroundColor: "#A68CBE", // Soft Coral
    color: "#FFFFFF", // White text for contrast
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    fontSize: "16px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF4C40", // Darker coral for hover effect
      transform: "scale(1.05)", // Scale effect on hover
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Shadow on hover
    },
  },
});



const Landing = () => {
  const navigate = useNavigate("");
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${BASEURL}/api/property/listallproperties?limit=6`)
      .then((res) => {
        setProperties(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => (console.log(err), setLoading(false)));
  }, []);
  return (
    <Box bgcolor={"#FAF9F6"}>
      {/* <Stack
        height={730}
        bgcolor={"gray"}
        sx={{
          backgroundImage: "url('/assets/real-estate-cost.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Stack textAlign={"center"} color={"#d4d2cd"} mt={30}>
          <Typography fontSize={{ md: 70, xs: 40 }} fontWeight={600}>
            Find your dream Home
          </Typography>
          <Typography fontSize={{ md: 30, xs: 20 }} fontWeight={500}>
            Pathanamthitta Properties find it here!
          </Typography>
        </Stack>
      </Stack> */}
      <Grid container mt={5} px={2}>
        <Grid item md={6.5} xs={12}>
          <Stack direction={"row"} justifyContent={"center"}>
            <Box>
              <Typography fontSize={{ md: 17, xs: 15 }} fontWeight={500}>
              Kozhencherry Real Estate
              </Typography>
              <Typography
                fontSize={{ md: 70, xs: 30 }}
                fontWeight={600}
                lineHeight={1}
                mt={1}
              >
                Invest today in Your <br />
                <span style={{ color: "orange" }}> Dream Home</span>
              </Typography>
              <Typography
                fontSize={{ md: 17, xs: 15 }}
                fontWeight={500}
                color={"gray"}
                mt={1}
              >
                We finds you the best properties around Pathanamthitta
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box
            width={{ xs: "100%", md: "auto" }}
            component={"img"}
            src="/assets/banner-img-C9N0pCHn.png"
          />
        </Grid>
      </Grid>
      <Stack
        sx={{ background: "#FAF9F6" }}
        alignItems={"center"}
        width={"100%"}
        my={3}
      >
        <Stack px={{ md: 20, xs: 2 }}>
          <Typography
            sx={{
              color: "orange", // Text color set to white for contrast
              bgcolor: "#1f1f1f", // Background color set to dark gray
              width: 150,
              p: 2,
              textAlign: "center",
              mt: 4,
              borderRadius: "4px", // Optional: adds some rounded corners
            }}
          >
            LATEST PROPERTY
          </Typography>
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent={"space-between"}
            my={2}
          >
            <Typography color={"#333333"} fontSize={40} fontWeight={500}>
              Latest properties for you!
            </Typography>
            {/* <Button
              onClick={() => navigate("properties")}
              variant="contained"
              sx={{
                bgcolor: "#5BC0EB",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#5BC0EB",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                },
                px: 3,
                py: 1.5,
                fontSize: 16,
              }}
            >
              View more <ArrowForwardIcon />
            </Button> */}
          </Stack>
          <PropertyCard page={'Landing'} data={properties} loading={loading} />
          <Stack direction={"row"} justifyContent={"center"} mb={5} mt={4}>
            <Button
              onClick={() => navigate("properties")}
              variant="contained"
              sx={{
                bgcolor: "#1f1f1f",
                color: "orange",
                "&:hover": {
                  backgroundColor: "#1f1f1f",
                  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
                },
                px: 3,
                py: 1.5,
                fontSize: 16,
              }}
            >
              See all listing <ArrowForwardIcon />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Box bgcolor={"#FAF9F6"} px={{ md: 20, xs: 2 }} my={2} py={2}>
        <Stack alignItems="center">
          <Typography variant="h4" fontWeight={600} color="#333333" mb={3}>
            Why Choose Us?
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            color="#666666"
            px={{ md: 10, xs: 2 }}
          >
            We provide the best properties with exceptional services to ensure
            you find your dream home effortlessly!
          </Typography>
        </Stack>
        <Grid container spacing={4} justifyContent="center" mt={4}>
          {/* Feature 1 */}
          <Grid item md={4} xs={12}>
            <Box
              bgcolor={"#ffffff"}
              borderRadius={4}
              boxShadow={3}
              p={3}
              textAlign="center"
            >
              <img
                src="https://plus.unsplash.com/premium_photo-1679856789403-c6fd2d5ec83c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Keys for House"
                style={{ width: "100%", height: "300px", objectFit: "cover" }}
              />
              <Typography variant="h6" fontWeight={500} mt={2} color="#333333">
                Expert Guidance
              </Typography>
              <Typography variant="body2" color="#666666">
                Our experienced team is here to guide you at every step.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 2 */}
          <Grid item md={4} xs={12}>
            <Box
              bgcolor={"#ffffff"}
              borderRadius={4}
              boxShadow={3}
              p={3}
              textAlign="center"
            >
              <img
                src="https://images.unsplash.com/photo-1560184897-ae75f418493e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Keys for House"
                style={{ width: "100%", height: "300px", objectFit: "cover" }}
              />
              <Typography variant="h6" fontWeight={500} mt={2} color="#333333">
                Best Listings
              </Typography>
              <Typography variant="body2" color="#666666">
                Explore a wide range of properties tailored to your needs.
              </Typography>
            </Box>
          </Grid>

          {/* Feature 3 */}
          <Grid item md={4} xs={12}>
            <Box
              bgcolor={"#ffffff"}
              borderRadius={4}
              boxShadow={3}
              p={3}
              textAlign="center"
            >
              <img
                src="https://plus.unsplash.com/premium_photo-1661310095024-fb47bfc8ad01?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Customer Satisfaction"
                style={{ width: "100%", height: "300px", objectFit: "cover" }}
              />
              <Typography variant="h6" fontWeight={500} mt={2} color="#333333">
                Customer Satisfaction
              </Typography>
              <Typography variant="body2" color="#666666">
                We prioritize your satisfaction and strive to exceed
                expectations.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="center" mt={4}>
          <Button
            onClick={() => navigate("properties")}
            variant="contained"
            sx={{
              bgcolor: "#1f1f1f",
              color: "orange",
              "&:hover": {
                backgroundColor: "#1f1f1f",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
              },
              px: 3,
              py: 1.5,
              fontSize: 16,
            }}
          >
            Explore More
          </Button>
        </Stack>
      </Box>
      {/* <Box bgcolor={'white'}>
        <Stack direction={'row'} justifyContent={'center'}> 
      <Typography
            color={"orange"}
            bgcolor={"#FAF9F6"}
            width={200}
            p={2}
            textAlign={"center"}
            mt={4}
          >
        PROPERTY TYPE
          </Typography></Stack>
        </Box> */}
    </Box>
  );
};

export default Landing;
