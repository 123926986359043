import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { House } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import AdbIcon from "@mui/icons-material/Adb";

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("user"));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box>
      <AppBar position="static" sx={{ bgcolor: "#222020" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              component={"img"}
              width={50}
              height={50}
              src="/assets/pngwing.com.png"
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, mb: 2, ml: -7 }}
            />
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 4,
                  display: { xs: "none", md: "flex" },
                  fontWeight: 600,
                  letterSpacing: ".2rem",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                Kozhencherry Real Estate
              </Typography>
            </Link>
            <Stack direction={"row"}>
              <Box
                component={"img"}
                mt={1}
                width={{ md: 35, xs: 30 }}
                height={{ md: 35, xs: 32 }}
                src="/assets/pngwing.com.png"
                sx={{ display: { md: "none", xs: "flex" }, mr: 1, mb: 2 }}
              />
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <Typography
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    display: { xs: "flex", md: "none" },
                    fontWeight: 600,
                    letterSpacing: ".1rem",
                    color: "white",
                    textDecoration: "none",
                    fontSize: {md:15,xs:13},
                    mt: 2.5,
                  }}
                >
                  Kozhencherry Real Estate
                </Typography>
              </Link>
            </Stack>
            <Stack
              width={"100%"}
              direction={"row"}
              justifyContent={"end"}
              alignItems={"center"}
            >
              {!admin && (
                <Button
                  onClick={() => navigate("/login")}
                  color="warning"
                  size="small"
                  sx={{ height: "30px" }}
                >
                  Login
                </Button>
              )}
              {admin?.isAdmin === true && (
                <Box ml={1}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu}>
                      <Avatar
                        sx={{
                          width: { xs: 11, md: 40 },
                          height: { xs: 11, md: 40 },
                          p: { xs: 1, md: 0 },
                        }}
                        alt={admin?.username}
                      >
                        {admin?.username.charAt(0).toUpperCase()}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography
                        onClick={() => navigate("/account")}
                        textAlign="center"
                      >
                        Account
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
