import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { propertyValidationSchema } from "../formValidations/PropertyValidation.js";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { BASEURL } from "../service/baseUrl.js";
import { useNavigate } from "react-router-dom";
const AddProperty = () => {
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");
  const [image5, setImage5] = useState("");
  const [image6, setImage6] = useState("");
  const [image1Preview, setImage1Preview] = useState("");
  const [image2Preview, setImage2Preview] = useState("");
  const [image3Preview, setImage3Preview] = useState("");
  const [image4Preview, setImage4Preview] = useState("");
  const [image5Preview, setImage5Preview] = useState("");
  const [image6Preview, setImage6Preview] = useState("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const [propertyData, setPropertyData] = useState({
    propertyTitle: "",
    place: "",
    propertyDescription: "",
    numberOfRooms: "",
    numberOfBathRooms: "",
    attachedBathRooms: "",
    cent: "",
    squareFeet: "",
    price: "",
    images: [],
    thumbnail: "",
    propertyCategory: "",
    locationUrl: "",
    serviceProvideType: "",
  });

  const handleAdd = async () => {
    const allImages = [image1, image2, image3, image4, image5, image6];
    try {
      await propertyValidationSchema.validate(
        {
          ...propertyData,
          images: allImages.filter(Boolean),
        },
        { abortEarly: false }
      );

      const formData = new FormData();
      formData.append("propertyTitle", propertyData.propertyTitle);
      formData.append("place", propertyData.place);
      formData.append("propertyDescription", propertyData.propertyDescription);
      formData.append("numberOfRooms", propertyData.numberOfRooms);
      formData.append("numberOfBathRooms", propertyData.numberOfBathRooms);
      formData.append("attachedBathRooms", propertyData.attachedBathRooms);
      formData.append("cent", propertyData.cent);
      formData.append("squareFeet", propertyData.squareFeet);
      formData.append("price", propertyData.price);
      allImages.forEach((image) => {
        if (image) {
          formData.append("images", image);
        }
      });
      formData.append("thumbnail", propertyData.thumbnail);
      formData.append("propertyCategory", propertyData.propertyCategory);
      formData.append("locationUrl", propertyData.locationUrl);
      formData.append("serviceProvideType", propertyData.serviceProvideType);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          `${BASEURL}/api/property/addproperty`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              user_token: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          toast.success("Property added!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setErrors(newErrors);
    }
  };

  //images preview
  useEffect(() => {
    if (image1) {
      setImage1Preview(URL.createObjectURL(image1));
    }
    if (image2) {
      setImage2Preview(URL.createObjectURL(image2));
    }
    if (image3) {
      setImage3Preview(URL.createObjectURL(image3));
    }
    if (image4) {
      setImage4Preview(URL.createObjectURL(image4));
    }
    if (image5) {
      setImage5Preview(URL.createObjectURL(image5));
    }
    if (image6) {
      setImage6Preview(URL.createObjectURL(image6));
    }
    if (propertyData.thumbnail) {
      setThumbnailPreview(URL.createObjectURL(propertyData.thumbnail));
    }
  }, [image1, image2, image3, image4, image5, image6, propertyData?.thumbnail]);
  return (
    <Stack bgcolor={"#f8f8f8"}>
      <>
        <Typography
          bgcolor={"black"}
          color={"white"}
          p={1}
          borderRadius={1}
          fontSize={20}
          fontWeight={500}
        >
          Property Basic Information
        </Typography>
        <Stack
          bgcolor={"white"}
          p={2}
          boxShadow={
            "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          }
          borderRadius={1}
          mb={3}
        >
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Property Title
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                propertyTitle: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px", // Set the border radius
                "& fieldset": {
                  borderColor: "#aeb1b5", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5", // Keep border color the same on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange", // Border color when focused
                },
              },
            }}
            variant="outlined"
            placeholder="Property Title"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.propertyTitle}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Place
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({ ...propertyData, place: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Place"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.place}</FormHelperText>
          <Stack
            direction={{ md: "row", xs: "column" }}
            justifyContent={"space-between"}
            mt={2}
          >
            <Typography fontSize={16} fontWeight={500} mt={2}>
              Property Category
            </Typography>
            <FormControl sx={{ width: { md: 400, xs: "100%" } }}>
              <Select
                onChange={(e) =>
                  setPropertyData({
                    ...propertyData,
                    propertyCategory: e.target.value,
                  })
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Border color when focused
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Keep it gray on hover
                  },
                }}
              >
                <MenuItem value={"House"}>House</MenuItem>
                <MenuItem value={"Villa"}>Villa</MenuItem>
                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                <MenuItem value={"Land"}>Land</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: "red" }}>
              {errors.propertyCategory}
            </FormHelperText>
            <Typography fontSize={16} fontWeight={500} mt={2}>
              Property Business Type
            </Typography>
            <FormControl sx={{ width: { md: 400, xs: "100%" } }}>
              <Select
                onChange={(e) =>
                  setPropertyData({
                    ...propertyData,
                    serviceProvideType: e.target.value,
                  })
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Border color when focused
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aeb1b5", // Keep it gray on hover
                  },
                }}
              >
                <MenuItem value={"Sale"}>Sale</MenuItem>
                <MenuItem value={"Rent"}>Rent</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: "red" }}>
              {errors.serviceProvideType}
            </FormHelperText>
          </Stack>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Property Description
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                propertyDescription: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                height: 100,
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Your Message"
            multiline
            rows={4}
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.propertyDescription}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Rooms
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                numberOfRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Rooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.numberOfRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Bathrooms
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                numberOfBathRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Bathrooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.numberOfBathRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Attached Bathrooms
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                attachedBathRooms: e.target.value,
              })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Attached Bathrooms"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.attachedBathRooms}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Cent
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({ ...propertyData, cent: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px", // Set the border radius
                "& fieldset": {
                  borderColor: "#aeb1b5", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5", // Keep border color the same on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange", // Border color when focused
                },
              },
            }}
            variant="outlined"
            placeholder="Cent"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.cent}</FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Area
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({ ...propertyData, squareFeet: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Sq Ft"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.squareFeet}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Price
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({ ...propertyData, price: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Price"
          />
          <FormHelperText sx={{ color: "red" }}>{errors.price}</FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Location URL(GoogleMap)
          </Typography>
          <TextField
            onChange={(e) =>
              setPropertyData({ ...propertyData, locationUrl: e.target.value })
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                "& fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&:hover fieldset": {
                  borderColor: "#aeb1b5",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "orange",
                },
              },
            }}
            variant="outlined"
            placeholder="Price"
          />
          <FormHelperText sx={{ color: "red" }}>
            {errors.locationUrl}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Thumbnail
          </Typography>
          <label htmlFor="thumbnailInput">
            <Stack
              bgcolor={"#dedede"}
              sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
              borderRadius={1}
            >
              <input
                onChange={(e) =>
                  setPropertyData({
                    ...propertyData,
                    thumbnail: e.target.files[0],
                  })
                }
                id="thumbnailInput"
                style={{ display: "none" }}
                type="file"
              />
              <Box
                textAlign={"center"}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                {thumbnailPreview ? (
                  <Box
                    component={"img"}
                    width={{ md: 404, xs: 323 }}
                    height={275}
                    src={thumbnailPreview}
                    alt=""
                  />
                ) : (
                  <>
                    {" "}
                    <PhotoLibraryIcon />
                    <Typography>Browse Image</Typography>
                  </>
                )}
              </Box>
            </Stack>
          </label>
          <FormHelperText sx={{ color: "red" }}>
            {errors.thumbnail}
          </FormHelperText>
          <Typography fontSize={16} fontWeight={500} mt={2}>
            Images
          </Typography>
          <Stack
            display={{ xs: "block", md: "flex", sm: "flex" }}
            flexDirection={"row"}
            sx={{ gap: 2 }}
            flexWrap={"wrap"}
          >
            <label htmlFor="img1">
              <Stack
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage1(e.target.files[0])}
                  id="img1"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image1Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image1Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img2">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage2(e.target.files[0])}
                  id="img2"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image2Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image2Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img3">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage3(e.target.files[0])}
                  id="img3"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image3Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image3Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            <label htmlFor="img4">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage4(e.target.files[0])}
                  id="img4"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image4Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image4Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>
            <label htmlFor="img5">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage5(e.target.files[0])}
                  id="img5"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image5Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image5Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>
            <label htmlFor="img6">
              <Stack
                mt={{ md: 0, xs: 1 }}
                bgcolor={"#dedede"}
                sx={{ width: { xs: 323, md: 404 }, height: "275px" }}
                borderRadius={1}
              >
                <input
                  onChange={(e) => setImage6(e.target.files[0])}
                  id="img6"
                  style={{ display: "none" }}
                  type="file"
                />
                <Box
                  textAlign={"center"}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {image6Preview ? (
                    <Box
                      component={"img"}
                      width={{ md: 404, xs: 323 }}
                      height={275}
                      src={image6Preview}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <PhotoLibraryIcon />
                      <Typography>Browse Image</Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </label>

            {errors.images && (
              <FormHelperText sx={{ color: "red" }}>
                {errors.images}
              </FormHelperText>
            )}
          </Stack>
          <Button
            onClick={handleAdd}
            size="large"
            sx={{
              marginTop: "15px",
              borderRadius: 1,
              height: 55,
              backgroundColor: "#fc7f03",
              fontSize: 16,
              fontWeight: 500,
              color: "white",
              "&:hover": { backgroundColor: "#fc6f03" },
            }}
          >
            Publish Property
          </Button>
        </Stack>
      </>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          padding: "10px",
          fontSize: "17px",
          fontFamily: "sans-serif",
        }}
      />
    </Stack>
  );
};

export default AddProperty;
