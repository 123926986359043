import {
  Box,
  Container,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import TuneIcon from "@mui/icons-material/Tune";
import PropertyCard from "../Components/PropertyCard";
import PageHeader from "../Components/PageHeader";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import axios from "axios";
import { BASEURL } from "../service/baseUrl";
import SearchIcon from "@mui/icons-material/Search";
//import TuneIcon from '@mui/icons-material/Tune';

const AllProperties = () => {
  const [filter, setFilter] = useState({
    propertyType: "all",
    price: "all",
    saleCategory: "all",
    search: "",
  });
  const [properties, setProperties] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState();
  const lastIndexOfItemInAPage = itemsPerPage * currentPage;
  const firstIndexOfItemInAPage = lastIndexOfItemInAPage - itemsPerPage;
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [totalProperties, setTotalProperties] = useState(null);
  useEffect(() => {
    const handler = setTimeout(() => {
      setFilter({ ...filter, search: searchData });
    }, 400);
    return () => {
      clearTimeout(handler);
    };
  }, [searchData]);

  useEffect(() => {
    axios
      .get(
        `${BASEURL}/api/property/listallproperties?search=${filter?.search}&&type=${filter?.propertyType}&&price=${filter?.price}&&service=${filter?.saleCategory}&&page=${currentPage}&&limit=${itemsPerPage}`
      )
      .then((res) => {
        setProperties(res?.data?.data);
        setCurrentPage(res?.data?.currentPage);
        setTotalProperties(res?.data?.count);
        setLoading(false);
      })
      .catch((err) => (console.log(err), setLoading(false)));
  }, [filter, currentPage]);
  return (
    <Box bgcolor={"#f8f8f8"} mb={0}>
      <PageHeader data={{ name: "Properties", name2: "Properties" }} />
      <Container>
        <Stack
          direction={{ md: "row", xs: "column" }}
          mt={5}
          spacing={2}
          justifyContent={"space-between"}
        >
          <Box sx={{ minWidth: 200 }}>
            <FormControl
              fullWidth
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}
            >
              <Select
                size="small"
                startAdornment={<HomeIcon sx={{ mr: 1 }} />}
                value={filter.propertyType}
                onChange={(e) =>
                  setFilter({ ...filter, propertyType: e.target.value })
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)", // Ensure no change on hover
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Changes border color on focus
                  },
                  "& .MuiSelect-icon": {
                    color: "orange", // Changes select arrow color on focus
                  },
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"House"}>House</MenuItem>
                <MenuItem value={"Villa"}>Villa</MenuItem>
                <MenuItem value={"Apartment"}>Apartment</MenuItem>
                <MenuItem value={"Land"}>Land</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 200 }}>
            <FormControl
              fullWidth
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}
            >
              <Select
                size="small"
                startAdornment={<CurrencyRupeeIcon sx={{ mr: 1 }} />}
                value={filter.price}
                onChange={(e) =>
                  setFilter({ ...filter, price: e.target.value })
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)", // Ensure no change on hover
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Changes border color on focus
                  },
                  "& .MuiSelect-icon": {
                    color: "orange", // Changes select arrow color on focus
                  },
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={3000000}> less than 3000000</MenuItem>
                <MenuItem value={6000000}>less than 6000000</MenuItem>
                <MenuItem value={10000000}>less than 10000000</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Stack direction={"row"} sx={{ minWidth: 200 }}>
            <FormControl
              fullWidth
              sx={{ "& .MuiOutlinedInput-root": { borderRadius: "25px" } }}
            >
              <Select
                startAdornment={<TuneIcon sx={{ mr: 1 }} />}
                size="small"
                value={filter.saleCategory}
                onChange={(e) =>
                  setFilter({ ...filter, saleCategory: e.target.value })
                }
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "&:hover": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.23)", // Ensure no change on hover
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "orange", // Changes border color on focus
                  },
                  "& .MuiSelect-icon": {
                    color: "orange", // Changes select arrow color on focus
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value={"Sale"}>Sale</MenuItem>
                <MenuItem value={"Rent"}>Rent</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
        <Stack mt={2} direction={"row"} justifyContent={"center"}>
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: 380, md: 600 },
              border: `1px solid ${isFocused ? "orange" : "transparent"}`,
              transition: "border-color 0.3s",
            }}
          >
            <InputBase
              onChange={(e) => setSearchData(e.target.value)}
              sx={{ ml: 1, flex: 3 }}
              placeholder="Search Properties!"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px", color: "orange" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Stack>
      </Container>
      <Stack px={{ md: 0, xs: 2, sm: 0 }} ml={{ md: 5, xs: 0 }} mt={4}>
        <PropertyCard
          pagination={{
            firstIndexOfItemInAPage: firstIndexOfItemInAPage,
            lastIndexOfItemInAPage: lastIndexOfItemInAPage,
          }}
          data={properties}
          loading={loading}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        width={"100%"}
        mt={2}
        mb={2}
      >
        {properties?.length !== 0 && (
          <Pagination
            count={Math.ceil(totalProperties / itemsPerPage)}
            onChange={(e, pageNumber) => {
              setCurrentPage(pageNumber);
              setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }, 0);
            }}
            color="warning"
          />
        )}
      </Stack>
    </Box>
  );
};

export default AllProperties;
