import { Route, Routes, Navigate } from "react-router-dom";
import Landing from "./Pages/Landing";
import AllProperties from "./Pages/AllProperties";
import PropertyDetails from "./Pages/PropertyDetails";
import AddProperty from "./Components/AddProperty";
import Account from "./Pages/Account";
import Layout from "./Components/Layout";
import ScrollToTop from "./Components/ScrollToTop";
import Login from "./Pages/Login";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Landing />} />
          <Route path="/properties" element={<AllProperties />} />
          <Route path="/propertyDetails/:id" element={<PropertyDetails />} />
          <Route path="/add-property" element={<AddProperty />} />
          <Route
            path="/account"
            element={<PrivateRoute component={<Account />} />}
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
