import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import {  WhatsApp, YouTube, Instagram } from "@mui/icons-material";
const Footer = () => {
  return (
    <>
      <Box class="site-footer">
        <Container>
          <Grid container>
            <Grid item sm={12} md={6} p={3}>
              <Typography variant="h6">About</Typography>
              <Typography mt={2} textAlign={"justify"} fontSize={15}>
                <span className="hdng fs-5 font-medium">
                  Kozhencherry Real Estate
                </span>
                <br />
                We offers a perfect mix of natural beauty and modern amenities.
                From residential plots to commercial spaces, it’s an ideal
                location for investors and homebuyers alike, with excellent
                connectivity and cultural significance.
              </Typography>
            </Grid>

            <Grid item xs={6} md={2} p={3}>
              <Typography variant="h6" mb={2}>
                Categories
              </Typography>
              <Stack class="footer-links">
                <Link to={"/properties"}>
                  {" "}
                  <Typography fontSize={15}>
                    <a href="">Properties </a>
                  </Typography>
                </Link>
                <Link to={"/account"}>
                  {" "}
                  <Typography fontSize={15}>
                    <a href="">Account</a>
                  </Typography>
                </Link>
              </Stack>
            </Grid>

            <Grid item xs={6} md={2} p={3}>
              <Typography variant="h6" mb={2}>
                Quick Links
              </Typography>
              <Stack class="footer-links">
                <Typography fontSize={15}>
                  <a href="">About Us</a>
                </Typography>
                <Typography fontSize={15}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://preview.themeforest.net/item/cityscape-real-estate-react-js-template/full_screen_preview/51644970?_ga=2.184083234.54663360.1718893109-1379862010.1718893109"
                  >
                    Contact Us
                  </a>
                </Typography>
                <Typography fontSize={15}>
                  <a href="">Privacy Policy</a>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} md={2} p={3}>
              <Typography variant="h6" mb={2}>
                Follow Us On
              </Typography>
              <Stack direction="row" spacing={0}>
                <IconButton
                  aria-label="Whatsapp"
                  color="primary"
                  component="a"
                  href="https://whatsapp.com/channel/0029Va5Awzr1CYoWDspbyz0h"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsApp />
                </IconButton>
                {/* <IconButton
                  aria-label="Facebook"
                  color="primary"
                  component="a"
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Facebook />
                </IconButton> */}

                <IconButton
                  aria-label="Instagram"
                  color="primary"
                  component="a"
                  href="https://www.instagram.com/ajith_kozhencherry?igsh=ZHJtdTd1ZmZsdG1y"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  aria-label="YouTube"
                  color="primary"
                  component="a"
                  href="https://youtube.com/@ajithvlog6908?si=tT71hiJSf7EnM3YB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <YouTube />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
          <hr />
        </Container>
        <Container>
          <Grid>
            <Grid item md={8} sm={6} xs={12} p={3}>
              <Typography fontSize={15} class="copyright-text">
                Copyright &copy; 2024 All Rights Reserved by Kozhencherry Real
                Estate
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
