import { House } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddProperty from "../Components/AddProperty";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import PageHeader from "../Components/PageHeader";
import EditProperty from "../Components/EditProperty";
import axios from "axios";
import { BASEURL } from "../service/baseUrl";
import toast, { Toaster } from "react-hot-toast";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const MyProperties = () => {
  const [properties, setProperties] = useState([]);
  const [selectedBtn, setSelectedBtn] = useState("View");
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState();
  const [loading, setLoading] = useState(true);
  const [totalProperties, setTotalProperties] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  //pagination
  const lastIndexOfItemInAPage = itemsPerPage * currentPage;
  const firstIndexOfItemInAPage = lastIndexOfItemInAPage - itemsPerPage;

  const handleOpen = () => setOpen(true);
  const handleClose = () => (setOpen(false), window.location.reload());

  const handleDelete = async (id) => {
    try {
      const response = await axios.put(
        `${BASEURL}/api/property/deleteproperty?id=${id}`
      );
      if (response.data) {
        toast.success("Property deleted!");
        handleDialogClose();
        getProperty();
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Something went wrong!";
      toast.error(errorMessage);
    }
  };

  const getProperty = async () => {
    await axios
      .get(
        `${BASEURL}/api/property/listallproperties?limit=${itemsPerPage}&&page=${currentPage}`
      )
      .then((res) => {
        setProperties(res?.data?.data);
        setCurrentPage(res?.data?.currentPage);
        setTotalProperties(res?.data?.count);
        setLoading(false);
      })
      .catch((err) => (console.log(err), setLoading(false)));
  };

  //logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login", { replace: true });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLogoutDialogOpen = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = () => {
    setLogoutDialogOpen(false);
  };

  useEffect(() => {
    getProperty();
  }, [currentPage]);

  const myProperties = properties?.map((item, index) => (
    <TableRow
      key={index}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Stack direction={"row"} spacing={2}>
          <Box
            borderRadius={3}
            component={"img"}
            width={90}
            height={60}
            src={item.thumbnail}
          />
          <Box>
            <Typography fontWeight={500} ml={0.5} fontSize={19}>
              {item.propertyTitle}
            </Typography>
            <Stack direction={"row"}>
              <LocationOnIcon sx={{ width: 20, color: "orange" }} />
              <Typography fontWeight={500} fontSize={15} color={"gray"}>
                {item.place}
              </Typography>
            </Stack>
            <Typography fontWeight={500} ml={0.8} fontSize={15}>
              Price : ₹{new Intl.NumberFormat("en-IN").format(item.price)}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell align="center">
        {new Date(item.createdAt).toLocaleDateString("en-GB")}
      </TableCell>
      <TableCell align="center">
        {" "}
        <EditIcon
          onClick={() => (handleOpen(), setId(item?._id))}
          sx={{ cursor: "pointer" }}
        />
        <DeleteOutlineIcon
          onClick={() => (handleDialogOpen(), setId(item?._id))}
          sx={{
            ml: { md: 2, xs: 0 },
            p: { md: 0, xs: 2 },
            cursor: "pointer",
          }}
        />
      </TableCell>
    </TableRow>
  ));

  return (
    <Stack bgcolor={"#f8f8f8"} minHeight={"100vh"}>
      <PageHeader data={{ name: "Account", name2: "Manage Property" }} />
      <Grid container mt={4} px={{ md: 12, xs: 2 }} spacing={3}>
        <Grid item md={3} xs={12}>
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
            component="nav"
          >
            <ListItemButton
              onClick={() => (setSelectedBtn("View"), getProperty())}
              sx={{
                ":hover": {
                  color: selectedBtn !== "View" ? "orange" : "white",
                  bgcolor: selectedBtn !== "View" ? "transparent" : "orange",
                },
                bgcolor: selectedBtn === "View" ? "orange" : "",
                color: selectedBtn === "View" ? "white" : "black",
              }}
            >
              <Stack direction={"row"} spacing={3}>
                <FormatListBulletedIcon />
                <ListItemText sx={{ fontSize: 500 }} primary="My Properties" />
              </Stack>
            </ListItemButton>
            <ListItemButton
              disableTouchRipple
              onClick={() => setSelectedBtn("Add")}
              sx={{
                ":hover": {
                  color: selectedBtn !== "Add" ? "orange" : "white",
                  bgcolor: selectedBtn !== "Add" ? "transparent" : "orange",
                },
                bgcolor: selectedBtn === "Add" ? "orange" : "",
                color: selectedBtn === "Add" ? "white" : "black",
              }}
            >
              <Stack direction={"row"} spacing={3}>
                <House />
                <ListItemText sx={{ fontSize: 500 }} primary="Add Property" />
              </Stack>
            </ListItemButton>
            <ListItemButton
              disableTouchRipple
              onClick={() => setSelectedBtn("Logout")}
              sx={{
                ":hover": {
                  color: selectedBtn !== "Logout" ? "orange" : "white",
                  bgcolor: selectedBtn !== "Logout" ? "transparent" : "orange",
                },
                bgcolor: selectedBtn === "Logout" ? "orange" : "",
                color: selectedBtn === "Logout" ? "white" : "black",
              }}
            >
              <Stack direction={"row"} spacing={3}>
                <LogoutIcon />
                <ListItemText
                  onClick={handleLogoutDialogOpen}
                  sx={{ fontSize: 500 }}
                  primary="Logout"
                />
              </Stack>
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={9} xs={12} mt={{ md: 0, xs: 2 }}>
          {selectedBtn === "View" ? (
            <>
              {loading ? (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  width={"100%"}
                >
                  <CircularProgress sx={{ color: "orange" }} />
                </Stack>
              ) : properties?.length !== 0 ? (
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>My Properties</TableCell>
                        <TableCell align="center">Date Added</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{myProperties}</TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  width={"100%"}
                  fontWeight={500}
                  textAlign={"center"}
                >
                  No properties availbale!
                </Typography>
              )}
              <Stack mb={2} direction={"row"} justifyContent={"center"}>
                {properties?.length !== 0 && (
                  <Pagination
                    count={Math.ceil(totalProperties / itemsPerPage)}
                    onChange={(e, pageNumber) => {
                      setCurrentPage(pageNumber);
                      setTimeout(() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }, 0);
                    }}
                    color="warning"
                  />
                )}
              </Stack>
            </>
          ) : (
            <AddProperty />
          )}
        </Grid>
      </Grid>
      <Modal
        sx={{ overflowX: "auto", p: 2.2 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack width={{ md: 860, xs: "100%" }} m={"auto"}>
          <EditProperty id={id} close={handleClose} />
        </Stack>
      </Modal>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle fontSize={17} id="alert-dialog-title">
          Do you want to delete this property?{" "}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Typography fontSize={15} fontWeight={500} color={"orange"}>
              Cancel
            </Typography>
          </Button>
          <Button onClick={() => handleDelete(id)} autoFocus>
            <Typography fontSize={15} fontWeight={500} color={"red"}>
              Delete
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={logoutDialogOpen}
        onClose={handleLogoutDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle fontSize={17} id="logout-dialog-title">
          {"Confirm Logout"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose}>
            <Typography fontSize={15} fontWeight={500} color={"orange"}>
              Cancel
            </Typography>
          </Button>
          <Button onClick={() => handleLogout()} autoFocus>
            <Typography fontSize={15} fontWeight={500} color={"red"}>
              Logout
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{
          padding: "10px",
          fontSize: "17px",
          fontFamily: "sans-serif",
        }}
      />
    </Stack>
  );
};

export default MyProperties;
