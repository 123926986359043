import React, { useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { House } from "@mui/icons-material";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation, useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const admin = JSON.parse(localStorage.getItem("user"));
  const currentPath = location.pathname;
  return (
    <Stack
      bgcolor={"#1f1f1f"}
      width={"100%"}
      position={"fixed"}
      bottom={0} // Ensure it's positioned at the bottom
      display={{ xs: "flex", md: "none" }}
    >
      <Stack direction={"row"} justifyContent={"space-evenly"} p={0.5}>
        <IconButton
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          <House
            sx={{
              color: currentPath === "/" ? "orange" : "white",
              width: 30,
              height: 40,
            }}
          />
        </IconButton>
        {/* {}
        <IconButton
          onClick={() => {
            navigate("/account");
          }}
        >
          <AccountCircleIcon
            sx={{
              color: currentPath === "/account" ? "orange" : "white",
              width: 30,
              height: 40,
            }}
          />
        </IconButton> */}
        <IconButton
          onClick={() => {
            navigate("/properties");
          }}
        >
          {" "}
          <MapsHomeWorkIcon
            sx={{
              color: currentPath === "/properties" ? "orange" : "white",
              width: 30,
              height: 40,
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MobileMenu;
