import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BathtubIcon from "@mui/icons-material/Bathtub";
import { useNavigate } from "react-router-dom";

import BedIcon from "@mui/icons-material/Bed";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  card: {
    position: "relative",
    borderRadius: "15px",
    overflow: "hidden",
    transition: ".2s ease-in-out",
    "&:hover": {
      transform: "scale(1.00)",
      boxShadow: "0 5px 20px rgba(0, 0, 0, 0.2)",
    },
  },
  media: {
    height: 230,
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gradientOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
  },
  content: {
    zIndex: 2,
    position: "relative",
    color: "#4e342e ",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  icon: {
    marginLeft: "16px",
  },
});

const PropertyCard = ({ page, data, pagination, loading }) => {
  const classes = useStyles();
  const navigate = useNavigate("");
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);

  const showProperties = data?.map((item, index) => (
      <Grid item key={index} md={page === "Landing" ? 4 : 3} xs={12}>
        <Card className={classes.card} key={index} sx={{ borderRadius: 2 }}>
          <Box
            component="img"
            className={classes.media}
            src={item?.thumbnail}
            alt={`${item?.propertyTitle?.slice(0, 24)}...`}
            style={{
              width: "100%",
              height: "270px",
              objectFit: "cover",
            }}
          />
          <CardContent className={classes.content}>
            <Typography variant="h5" component="div" fontWeight={500}>
              {item?.propertyTitle?.length > 25
                ? `${item.propertyTitle.slice(0, 25)}...`
                : item?.propertyTitle}
            </Typography>

            <Typography
              variant="body2"
              component="p"
              style={{ marginBottom: "8px", fontWeight: 500 }}
            >
              {item?.place}
            </Typography>

            <Box className={classes.iconContainer}>
              <BedIcon />
              <Typography variant="body2" fontWeight={500}>
                {item?.numberOfRooms} Bedrooms
              </Typography>
              <BathtubIcon className={classes.icon} />
              <Typography variant="body2">
                {item?.numberOfBathRooms} Bathrooms
              </Typography>
            </Box>
          </CardContent>
          <Divider variant="middle" sx={{ bgcolor: "gray" }} />
          <Stack
            direction={"row"}
            px={2}
            mt={1}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={600} mt={1}>
              ₹{new Intl.NumberFormat("en-IN").format(item.price)}
            </Typography>
            <Button
              onClick={() => navigate(`/propertyDetails/${item?._id}`)}
              variant="text"
              color="warning"
              sx={{ fontWeight: 600 }}
            >
              View Property
            </Button>
          </Stack>
        </Card>
      </Grid>
    ));

  return (
    <>
      <Grid container>
        <Grid item xs={12} m={{ md: 3, xs: 0 }}>
          <Grid container spacing={3}>
            {loading ? (
              <Grid item xs={12} className={classes.loaderContainer}>
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  width={"100%"}
                >
                  <CircularProgress sx={{color:'orange'}} />
                </Stack>
              </Grid>
            ) : data?.length !== 0 ? (
              showProperties
            ) : (
              <Typography width={"100%"} fontWeight={500} textAlign={"center"}>
                No properties availbale!
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyCard;
