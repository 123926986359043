import * as Yup from "yup";

export const propertyValidationSchema = Yup.object({
  images: Yup.array()
    .min(1, "At least one image is required")
    .of(Yup.string().required("Image is required")),
  thumbnail: Yup.string().required("Thumbnail is required"),
  propertyTitle: Yup.string().required("Property title is required"),
  place: Yup.string().required("Place is required"),
  propertyDescription: Yup.string().required(
    "Property description is required"
  ),
  numberOfRooms: Yup.number()
    .required("No of rooms is required")
    .typeError("Rooms must be a number"),
  numberOfBathRooms: Yup.number()
    .required("No of bathrooms is required")
    .typeError("Bathrooms must be a number"),
  attachedBathRooms: Yup.number()
    .required("No of attached bathrooms is required")
    .typeError("Attached bathrooms must be a number"),
  cent: Yup.number()
    .required("Cent is required")
    .typeError("Cent must be a number"),
  squareFeet: Yup.number()
    .required("Area is required")
    .typeError("Sale price must be a number"),
  price: Yup.number()
    .required("Price is required")
    .typeError("Price must be a number"),
  propertyCategory: Yup.string().required("Property category is required"),
  serviceProvideType: Yup.string().required(
    "Property Business type is required"
  ),
  locationUrl: Yup.string().required("Location URL is required"),
});
