import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import MobileMenu from './MobileMenu';
import { Stack } from '@mui/material';
import Footer from './Footer';

const Layout = () => {
  return (
    <Stack mb={{md:0,xs:6}}>
      <Header />
      <Outlet />
      <Footer/> {/* This is where the routed content will be displayed */}
      <MobileMenu
        sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '70px',
            zIndex: 10, 
        }}
      />
    </Stack>
  );
};

export default Layout;
